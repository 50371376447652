import React, { useState, useEffect } from "react";
import { IMG_URL, API_URL } from "../../../Config";
import { Pagination } from "@mui/material";
import axios from "axios";
import "./RecentlyAddedAll.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";
const RecentlyAddedAll = () => {
  const [comic, setcomic] = useState([]);
  const [page, setpage] = useState(1);
  const [pageno, setpageno] = useState(1);
  const [load, setload] = useState(false)
  const navigate = useNavigate();
  const getTheLatestUpdatedComic = async (page) => {
    try {
      setload(true)
      const data = await axios.post(`${API_URL}/comic/search`, {
        page: page,
        type: "recentlyAdded",
      });
      setcomic(data.data.data);
      setpage(Math.ceil(data.data.totalData / 30));
      setload(false)
    } catch (error) {
      //console.log(error);
    }
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`)
  };
  const changePage = async (event, value) => {
    // //console.log(event,value);
    setpageno(value);
    getTheLatestUpdatedComic(value);
  };
  useEffect(() => {
    getTheLatestUpdatedComic(pageno);
  }, []);

  return (
    <div className="text-white">
      <div className="text-white">
        <div className="mt-5 mb-3 pt-2">
          <div
            className="back text-white d-flex "
            style={{ width: "90%", margin: "20px auto" }}
          >
            <h2>Recently Added</h2>
          </div>
          {load ? <center><ComicSwirl/></center> :<div>
          <div className="showLibrary">
            {comic != [] ? (
              comic.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="grid-item"
                    onClick={() => addHistory(data._id,data.title)}
                    data-text={data.title}
                  >
                    <img src={`${IMG_URL}${data.coverImage}`} />
                  </div>
                );
              })
            ) : (
              <div>Comics Not Found</div>
            )}
          </div>
          <div className="text-white text-center pagination">
            <Pagination
              count={page}
              page={pageno}
              defaultPage={pageno}
              onChange={changePage}
              color="secondary"
              showFirstButton
              showLastButton
            />
          </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default RecentlyAddedAll;
