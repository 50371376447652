import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Dashboard_Slider/Dashboard_Slider.css";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, IMG_URL } from "../../../Config";
import { lInk, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { data } from "jquery";
import ComicSwirl from "../loaders/ComicSwirl";

const Dashboard_Slider = () => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 2000,
    cssEase: "ease-out",
  };

  const cardsData = [
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore commodi unde temporibus! Alias doloremque quod dolorum! Architecto minima provident distinctio est! Ad, excepturi commodi? Impedit voluptates quaerat natus quam animi fugiat unde.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore commodi unde temporibus! Alias doloremque quod dolorum! Architecto minima provident distinctio est! Ad, excepturi commodi? Impedit voluptates quaerat natus quam animi fugiat unde.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      imgSrc:
        "https://mangadex.org/covers/17eebcc7-d993-446c-84be-c222bfb27794/a2b06f8e-c8e4-4b8e-b4be-467c6378f3aa.jpg.256.jpg",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    // Add more cards as needed
  ];

  const [get_popular10, set_popular10] = useState([]);

  const handle_popular10_data = async () => {
    await axios
      .post(`${API_URL}/comic/popularTop`, { search: "isPopular" })
      .then((res) => {
        if (res.data.status == "success") {
          set_popular10(res.data.data);
        } else {
          // //console.log(res.data.data);
          toast.error(res.data.data);
        }
      });
  };
  const showComic = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  const isLogin = () => {
    // //console.log(window.location.search);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    // //console.log(token);
    if (token) {
      Cookies.set("UAuthorization", token);
      window.location.replace("/");
    }
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  useEffect(() => {
    handle_popular10_data();
    isLogin();
  }, []);

  return (
    <center className="user_dashboard_slider">
      <div className="card_body1_img_title1">Popular New Titles</div>
      {get_popular10.length > 0 ? (
        <div className="slider-container">
          <Slider {...settings}>
            {get_popular10.map((card, index) => (
              <div
                key={index}
                style={{ cursor: "pointer" }}
                // onClick={() => showComic(card._id,card.title)}
                className=""
              >
                <div
                  class="card-body1"
                  style={{
                    position: "relative",
                    width: "100%",
                    backgroundImage: `url("${IMG_URL}${card.coverImage}")`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "0px 30%",
                    height: "500px",
                    backgroundColor: "rgba(0, 0, 0, 0.54)",
                    backgroundBlendMode: "multiply",
                  }}
                >
                  <div
                    class="card_body1_img"
                    // style={{ background: `url("${IMG_URL}${card.coverImage}")` }}
                    onClick={() => addHistory(card._id,card.title)}
                  >
                  <img
                  src={`${IMG_URL}${card.coverImage}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                  alt={card.title}
                  />  
                  </div>

                  <div className="card_body1_content_container">
                    <p className="card_body1_title">{card.title}</p>
                    <div
                      // style={{ display: "flex", gap: "6px", position: "absolute" , top : "40%" , left : "23%" }}
                      style={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                      className="genreButton"
                    >
                      {card.genre.split(",").map((data, i) => {
                        if (i == 0) {
                          return (
                            <a
                              className="btn genre_title"
                              href={`/genre/${data}`}
                              style={{
                                backgroundColor: "#da8ee7",
                                color: "white",
                                padding: "0",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                fontSize: ".8em",
                                fontFamily: "poppins",
                                fontWeight: "600",
                              }}
                            >
                              {data}
                            </a>
                          );
                        } else {
                          return (
                            <a
                              href={`/genre/${data}`}
                              className="btn genre_title"
                              style={{
                                backgroundColor: "#2C2C2C",
                                color: "white",
                                padding: "0",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                fontSize: ".8em",
                                fontFamily: "poppins",
                                fontWeight: "600",
                              }}
                            >
                              {data}
                            </a>
                          );
                        }
                      })}
                    </div>
                    <div className="card_body1_description_container">
                      <p className="card_body1_description">
                        {card.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <center>
          <ComicSwirl />
        </center>
      )}
    </center>
  );
};

export default Dashboard_Slider;
