import { AccessTimeRounded } from "@mui/icons-material";
import { common } from "@mui/material/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, IMG_URL } from "../../../Config";
import Cookies from "js-cookie";
import moment from "moment";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Pagination } from "@mui/material";
import "./ReadingHistory.css";
import google_icon from "./Assets/search.png";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";
dayjs.extend(relativeTime);
const ReadingHistory = () => {
  const [comic, setcomic] = useState([]);
  const [page, setpage] = useState(0);
  const [pageno, setpageno] = useState(1);
  const [comics, setcomics] = useState([]);
  const [load, setload] = useState(false);
  const navigate = useNavigate();
  const getUserHistory = async () => {
    setload(true);
    if (Cookies.get("UAuthorization")) {
      const data = await axios.get(`${API_URL}/user/get/history`, {
        headers: {
          Authorization: Cookies.get("UAuthorization"),
        },
      });
      if (data.data.data) {
        // //console.log(new Date(data.data.data[2].date).toLocaleString());
        setcomic(data.data.data);
        setpage(data.data.count < 10 ? 1 : Math.ceil(data.data.count / 10));
        setcomics(data.data.data.slice((pageno - 1) * 10, 10 * pageno));
      }
    }
    setload(false);
  };
  const changePage = async (event, value) => {
    setpageno(value);
    setcomics(comic.slice((value - 1) * 10, 10 * value));
  };
  const addHistory = async (id, title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  useEffect(() => {
    getUserHistory();
  }, []);

  return (
    <div className="mt-5 pt-2">
      <div
        className="back text-white d-flex "
        style={{ width: "90%", margin: "0px auto" }}
      >
        <h2>Reading History</h2>
      </div>
      {load ? <center><ComicSwirl/></center>:
        <div className="showHistory">
          {comics != [] ? (
            comics.map((data, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between h-25 mb-1 "
                  Style="background-color: #2c2c2c;
                       padding: 14px 18px;
                       width: 70%;
                       margin: 16px auto;
                       border-radius: 7px;"
                  onClick={() =>
                    addHistory(data.comicId._id, data.comicId.title)
                  }
                >
                  <div>
                    <img
                      src={`${IMG_URL}${data.comicId.coverImage}`}
                      height={80}
                      className=""
                    />{" "}
                    <span className="text-white" style={{ marginLeft: "6px" }}>
                      {data.comicId.title}
                    </span>
                  </div>
                  <div>
                    <AccessTimeRounded sx={{ color: common["white"] }} />
                    <span className="text-white">
                      {" "}
                      {dayjs(data.date).fromNow()}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div>History Not Found</div>
          )}
          {page > 0 ? (
            <div className="text-white text-center pagination">
              <Pagination
                count={page}
                page={pageno}
                defaultPage={1}
                onChange={changePage}
                color="secondary"
                showFirstButton
                showLastButton
              />
            </div>
          ) : (
            ""
          )}
        </div>
      }
      {!Cookies.get("UAuthorization") && (
        <div>
          <center style={{ marginTop: "6%" }}>
            <button
              className="signin_btn"
              onClick={() =>
                (window.location.href = `${API_URL}/user/auth/google`)
              }
            >
              {" "}
              <img
                src={google_icon}
                alt=""
                style={{ width: "25px", height: "25px" }}
              />{" "}
              Sign In
            </button>
            <button
              className="signin_btn"
              onClick={() =>
                (window.location.href = `${API_URL}/user/auth/google`)
              }
            >
              {" "}
              <img
                src={google_icon}
                alt=""
                style={{ width: "25px", height: "25px" }}
              />{" "}
              Sign Up
            </button>
            <h3
              className=" text-center mt-5 text-capitalize  "
              style={{ color: "rgb(218, 142, 231)", fontFamily: "monospace" }}
            >
              For The Reading History Please Sign In
            </h3>
          </center>
        </div>
      )}
    </div>
  );
};

export default ReadingHistory;
