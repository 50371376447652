import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, IMG_URL } from "../../../Config";
import { EastSharp } from "@mui/icons-material";
import Adsense from "../adSense/Adsense";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";
const GenreSliders = () => {
  const [comics, setComics] = useState([]);
  const navigate =useNavigate()
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false, // Remove arrows
    responsive: [
      {
        breakpoint: 2660,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
    ],
  };
  const comicsData = async () => {
    try {
      const data = await axios.get(`${API_URL}/comic/genre/comic`);
      //console.log(data.data.data);
      
      setComics(data.data.data);
    } catch (error) {
      //console.log(error);
    }
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`)
  };
  useEffect(() => {
    comicsData();
  }, []);

  return (
    <div>
      {comics.length >0 ?( 
        comics.map((data, index) => {
          return (
            <div>
              <div
                className="container text-white slider-container4"
                Style="height:267px!important ;margin-top: 90px !important"
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "98%", margin: "18px auto" }}
                >
                  <h3 className="text-capitalize">{data._id}</h3>
                  <span
                    style={{ color: "White", cursor: "pointer" }}
                    onClick={() =>
                      (navigate(`/genre/${data._id}`))
                    }
                  >
                    See All <EastSharp style={{ cursor: "pointer" }} />
                  </span>

                  {/* <EastSharp
                  style={{ cursor: "pointer" }}
                  onClick={() => (navigate(`/genre/${data._id}`))}
                /> */}
                </div>
                <Slider {...sliderSettings}>
                  {data.documents.slice(0,10).map((card, index) => (
                    <div>
                      <div
                        key={index}
                        onClick={() => addHistory(card._id,card.title)}
                        Style="cursor:pointer"
                        className="card recently-card"
                      >
                        <img
                          src={`${IMG_URL}${card.coverImage}`}
                          className="card-img-top"
                          alt="..."
                        />
                      </div>
                      <h6 className="comicName  mt-2 text-sm text-white line-clamp-2">
                        {card.title}
                      </h6>
                    </div>
                  ))}
                </Slider>
              </div>
              {index !== 0 && index % 3 == 0 ? (
                <center>
                  <div className="dashboard_ad_container2" style={{marginTop:"6rem",marginBottom:"-2rem"}}>
                    <Adsense responsive={true} dataAdSlot="" />
                  </div>
                </center>
              ) : null}
            </div>
          );
        })):(<center><ComicSwirl/></center>)}
    </div>
  );
};

export default GenreSliders;
