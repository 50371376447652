import React, { useEffect, useState } from "react";
import "./Top10.css";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { API_URL, IMG_URL } from "../../../Config";
import { PeopleAltOutlined, PermIdentityOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComicSwirl from "../loaders/ComicSwirl";
const Top10 = () => {
  const [comic, setcomic] = useState([]);
  const navigate = useNavigate();
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 2660,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
    ],
  };
  const handle_popular10_data = async () => {
    await axios
      .post(`${API_URL}/comic/popularTop`, { search: "isTop10" })
      .then((res) => {
        if (res.data.status == "success") {
          setcomic(res.data.data);
          // //console.log(res.data.data);
        } else {
          toast.error(res.data.data);
        }
      });
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  useEffect(() => {
    handle_popular10_data();
  }, []);

  return (
    <div className="text-white">
      <div className="" style={{ width: "96%", margin: "10px auto" }}>
        <h3>Top 10 Comic</h3>
      </div>
      <div
        // className="top10 d-flex justify-content-between flex-wrap"
        // Style="width:95%;margin:2rem auto;"
        style={{width:"95%",margin:"2rem auto"}}
      >
        {comic.length > 0 ? (
          <Slider {...sliderSettings}>
            {comic.slice(0,10).map((card, index) => (
              <div>
                <div
                  key={index}
                  onClick={() => addHistory(card._id,card.title)}
                  Style="cursor:pointer;height:220px"
                  className="card recently-card"
                >
                  <img
                    src={`${IMG_URL}${card.coverImage}`}
                    className="top card-img-top"
                    style={{height:"220px !important"}}
                    alt="..."
                  />
                </div>
                <h6 className="comicName  mt-0 text-sm text-white">
                  {card.title}
                </h6>
              </div>
            ))}
          </Slider>
        ) : (
          <center><ComicSwirl/></center>
        )}
      </div>
    </div>
  );
};

export default Top10;
