import React, { useEffect, useState, useRef } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./User_Comic_Viewer.css";
import { API_URL, IMG_URL } from "../../../Config";
import axios from "axios";
import { useParams } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useButtonClick from "../utils/utils";
import Adsense from "../adSense/Adsense";
import { HelmetProvider } from "react-helmet-async";
import AdComponent from "../adSense/Adsense";
import EastIcon from "@mui/icons-material/East";
import ImageWithLoader from "../utils/ImageWithLoader";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CancelIcon from '@mui/icons-material/Cancel';

const User_Comic_Viewer2 = () => {
  const [viewMode, setViewMode] = useState("long-strip");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { ids } = useParams();
  const [langId, setLangId] = useState(ids);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [chapter, setchapter] = useState([]);
  const [details, setdetails] = useState({
    comic: "",
    chapter: "",
  });
  const galleryRef = useRef(null);

  const [count1, handleClick1] = useButtonClick("button1");
  const [count2, handleClick2] = useButtonClick("button2");
  const [count3, handleClick3] = useButtonClick("button3");
  const [count4, handleClick4] = useButtonClick("button4");
  const [count5, handleClick5] = useButtonClick("button5");

  document.addEventListener("DOMContentLoaded", () => {
    const imageStrip = document.getElementById("image-strip");

    imageStrip.addEventListener("click", (event) => {
      if (event.target.tagName === "IMG") {
        const imageIndex = event.target.getAttribute("data-index");
        //console.log(`Clicked image index: ${imageIndex}`);
      }
    });
  });

  const getComic = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/chapter/lang/${langId}`);
      const fetchedImages = data.data.map((imgPath) => ({
        original: `${IMG_URL}${imgPath.path}`,
        thumbnail: `${IMG_URL}${imgPath.path}`,
      }));
      setImages(fetchedImages);
      setchapter(data.chapterData);
      //console.log(data);
      setdetails({ comic: data.comic, chapter: data.chapter });
      // //console.log(data.chapterData);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getComic();
  }, [langId]);

  useEffect(() => {
    const handleScroll = () => {
      const galleryElement = galleryRef.current;
      if (galleryElement) {
        const currentPageIndex =
          Math.ceil(galleryElement.scrollLeft / galleryElement.offsetWidth) + 1;
        setCurrentPage(currentPageIndex);
      }
    };

    const galleryElement = galleryRef.current;
    if (galleryElement) {
      galleryElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (galleryElement) {
        galleryElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // const handlePageChange = (event) => {
  //   const page = parseInt(event.target.value);
  //   const pageElement = document.getElementById(`page-${page}`);
  //   if (pageElement) {
  //     pageElement.scrollIntoView({ behavior: "smooth" });
  //     setCurrentPage(page);
  //   }
  // };

  const handlePageChange = (event) => {
    handleModeButtonClick();
    const page =
      viewMode == "double"
        ? event.target.value != 1
          ? Math.floor(parseInt(event.target.value) / 2)
          : 1
        : parseInt(event.target.value);
    const pageElement = document.getElementById(`page-${page}`);
    if (pageElement) {
      //console.log(`Element found: ${pageElement}`);

      // Introducing a timeout
      setTimeout(() => {
        // Scroll into view
        pageElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        // Force repaint (optional)
        pageElement.style.display = "none";
        pageElement.style.display = "";

        setCurrentPage(page);
      }, 300);
    }
  };
  const handleChapterChange = async (event) => {
    handleModeButtonClick();
    setLangId(event.target.value);
  };
  const handleModeButtonClick = () => {
    if (window.innerWidth < 827) {
      setIsSidebarOpen(false); // Always close sidebar on button click in mobile view
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 827); // Open sidebar if window width is >= 827
    };


    // Initial setup
    handleResize();


    window.addEventListener("resize", handleResize);


    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const renderGallery = () => {
    switch (viewMode) {
      case "single":
        return <ImageGallery items={images} slideInterval={5000} />;
      case "double":
        return (
          <div className="double-page-view">
            {images.map(
              (image, index) =>
                index % 2 === 0 && (
                  <div
                    key={index}
                    id={`page-${Math.ceil((index + 1) / 2)}`}
                    className="double-page"
                  >
                    <img src={image.original} alt={image.description} />
                    {images[index + 1] && (
                      <img
                        src={images[index + 1].original}
                        alt={images[index + 1].description}
                      />
                    )}
                  </div>
                )
            )}
          </div>
        );
      case "multiple":
        return (
          <div className="multiple-pages-view">
            {images.map((image, index) => (
              <div key={index} className="page">
                <img
                  src={image.original}
                  alt={image.description}
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
            ))}
          </div>
        );
      case "long-strip":
        return (
          <div id="image-strip" className="long-strip-view" ref={galleryRef}>
            {images.map((image, index) => (
              <div
                key={index}
                id={`page-${index + 1}`} // Ensure this ID matches what you're targeting
                className="long-strip-page"
              >
                <ImageWithLoader
                  src={image.original}
                  dataIndex={index}
                  alt={image.description}
                />
              </div>
            ))}
          </div>
        );
      case "full-width":
        return (
          <div id="image-strip" className="full-width-view">
            {images.map((image, index) => (
              <div
                key={index}
                id={`page-${index + 1}`}
                className="full-width-page"
              >
                <ImageWithLoader src={image.original} alt={image.description} />
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <center>
        <div className="comic_view_ad_container3 mt-3 mb-3">
          <Adsense responsive={true} dataAdSlot="" />
        </div>  
        </center>
      </div>
      <div
        className={`comic-viewer-container ${
          isSidebarOpen ? "with-sidebar" : ""
        }`}
      >
        {renderGallery()}
        <button
          className="toggle-sidebar"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          style={{
            width: "30px",
            backgroundColor: "transparent",
            position: "fixed",
            top: "0px",
            marginTop: "-17px",
            fontSize: "2em",
            marginTop: "-17px",
            fontSize: "2em",
          }}
        >
          {isSidebarOpen ? (
            <CancelIcon style={{ fontSize: ".9em" }} />
          ) : (
            <FilterAltIcon style={{ fontSize: ".9em" }} />
          )}
         
        </button>

        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div className="view-mode-buttons text-white mt-3">
            <br />
            <h4 className="title">Title :- {details.comic}</h4>
            <h5 className="title" style={{ fontSize: "1rem" }}>
              Chapter :- {details.chapter}
            </h5>
            <div className="page-dropdown-container">
              <label htmlFor="page-select">Go to page:-</label>
              <select
                id="page-select"
                className="page-dropdown"
                onChange={handlePageChange}
              >
                {images.map((image, index) => (
                  <option key={index} value={index + 1}>
                    Page {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <div className="page-dropdown-container">
              <label htmlFor="page-select">Chapters:-</label>
              <select
                id="page-select"
                className="page-dropdown"
                onChange={handleChapterChange}
                value={langId}
              >
                {chapter &&
                  chapter.map((data, index) => (
                    <option key={index} value={data.langId}>
                      {data.chapter}
                    </option>
                  ))}
              </select>
            </div>
            <button
              className="mt-3"
              onClick={() => {
                setViewMode("single");
                handleModeButtonClick();
                handleClick1();
              }}
            >
              Single Page
            </button>
            <button
              onClick={() => {
                setViewMode("double");
                handleModeButtonClick();
                handleClick2();
              }}
            >
              Double Page
            </button>
            <button
              onClick={() => {
                setViewMode("multiple");
                handleModeButtonClick();
                handleClick3();
              }}
            >
              Multiple Pages
            </button>
            <button
              onClick={() => {
                setViewMode("long-strip");
                handleModeButtonClick();
                handleClick4();
              }}
            >
              Long Strip
            </button>
            <button
              onClick={() => {
                setViewMode("full-width");
                handleModeButtonClick();
                handleClick5();
              }}
            >
              Full Width
            </button>
          </div>
        </div>
      </div>
      <div>
      <center>
      <div className="comic_view_ad_container2 mt-3 mb-3">
          <Adsense responsive={true} dataAdSlot="" />
        </div>
      </center>
      </div>
    </div>
  );
};

export default User_Comic_Viewer2;

