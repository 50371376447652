import React from "react";
import Modal from "react-modal";
import Adsense from "./Adsense";

const AdSenseModal = ({ isOpen, onRequestClose }) => {
  //console.log(isOpen, onRequestClose);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          zIndex: 1000,
          background: "#000000c2",
          border: "none !important",
          overflow: "hidden !important",
          inset:"-25px",
        },
        content: {
          width: "300px",
          height: "647px",
          padding: " 0px 20px",
          margin: "auto",
          background: "#272727",
          border: "none !important",
        },
      }}
    >
      <div style={{ textAlign: "right", marginTop: "14px" }}>
        <div className="d-flex justify-content-between">
          <h5 className="text-white">Advertisment</h5>
          <button
            style={{
              background: "transparent",
              border: "0px",
              color: "white",
              fontSize: "24px",
            }}
            onClick={onRequestClose}
          >
            ✕
          </button>
        </div>
        <div
          style={{
            width: "290px",
            height: "540px",
            margin: "0px auto",
            marginLeft: "-15px",
            marginTop: "16px",
          }}
        >
          <Adsense responsive={true} dataAdSlot="" type="vertical" />
        </div>
      </div>
    </Modal>
  );
};

export default AdSenseModal;
