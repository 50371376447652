import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, IMG_URL } from "../../../Config";
const Adsense = ({ dataAdSlot, responsive, type }) => {
  const [adLoaded, setAdLoaded] = useState(true);
  const [randomAd, setRandomAd] = useState({});
  const getRandomAd = async () => {
    const data = await axios.get(
      `${API_URL}/ads/custom/random/${type ? "vertical" : "banner"}`
    );
    setRandomAd(data.data.data);
  };

  useEffect(() => {
    const loadAds = () => {
      const script = document.getElementsByClassName("script");
      script.onload = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        //console.log("run");
        setAdLoaded(false);
      };
    };
    getRandomAd();
    loadAds();
  }, []);
  return (
    <div
      Style="height: 100%;
    width: 100%;pointer-events:none"
    >
      {adLoaded ? (
        <a
          target="_blank"
          rel="noreferrer"
          style={{ pointerEvents: "none" }}
          href={randomAd.link}
        >
          <img
            src={IMG_URL + randomAd.image}
            alt={randomAd.link}
            style={{
              height: "100%",
              width: type ?  "100%" :"auto",
              objectFit: type ? "fill" : "contain",
              pointerEvents: "auto",
            }}
          />
        </a>
      ) : (
        <ins
          className="adsbygoogle"
          Style="display:block;pointer-events:none"
          data-ad-client="ca-pub-5883171753675259"
          data-ad-slot={dataAdSlot}
          data-ad-format="auto"
          data-full-width-responsive={responsive}
        ></ins>
      )}
    </div>
  );
};

export default Adsense;
