import { TextField } from "@mui/material";
import React, { useState } from "react";
import { API_URL } from "../../../Config";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
const ResetPassword = () => {
  const [password, setpassword] = useState({});
  const headersJson = {
    "Content-Type": "application/json",
    Authorization: `${Cookies.get("Authorization")}`,
  };
  const resetPass = async (e) => {
    e.preventDefault();
    try {
      const data = await axios.post(
        `${API_URL}/admin/reset-password`,
        password,
        { headers: headersJson }
      );
      //console.log(data);
      if (data.data.status == "success") {
        toast.success(data.data.message);
      } else {
        toast.error(data.data.message);
      }
    } catch (error) {
      //console.log(error);
      toast.warn(error.message);
    }
  };
  return (
    <div className="mt-3" style={{ width: "50%", margin: "0px auto" }}>
      <TextField
        id="outlined-basic"
        label="Old Password"
        variant="outlined"
        fullWidth
        value={password.oldPassword}
        onChange={(e) =>
          setpassword({ ...password, oldPassword: e.target.value })
        }
        name="Old Password"
        className="mb-3"
      />
      <TextField
        id="outlined-basic"
        label="New Password"
        variant="outlined"
        fullWidth
        value={password.newPassword}
        onChange={(e) =>
          setpassword({ ...password, newPassword: e.target.value })
        }
        name="New Password"
        className="mb-3"
      />
      <button
        type="button"
        onClick={(e) => resetPass(e)}
        className="btn btn-primary"
      >
        Reset password
      </button>
    </div>
  );
};

export default ResetPassword;
