import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { ImageList, TextField } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
  AddBox,
  AddBoxOutlined,
  EditAttributesRounded,
  EditNoteRounded,
  EditNotifications,
  ImageAspectRatioRounded,
  ModeEditOutline,
} from "@mui/icons-material";
import Select from "react-select";
import { toast } from "react-toastify";
import { API_URL, IMG_URL } from "../../../Config";
import axios from "axios";
import en from "../../../assets/flag/cif-gb.svg";
import fr from "../../../assets/flag/cif-fr.svg";
import kr from "../../../assets/flag/cif-kr.svg";
import cn from "../../../assets/flag/cif-cn.svg";
import tr from "../../../assets/flag/cif-tr.svg";
import jp from "../../../assets/flag/cif-jp.svg";
import In from "../../../assets/flag/cif-in.svg";
import br from "../../../assets/flag/cif-br.svg";
import id from "../../../assets/flag/cif-id.svg";
import pl from "../../../assets/flag/cif-pl.svg";
import ru from "../../../assets/flag/cif-ru.svg";
import mx from "../../../assets/flag/cif-mx.svg";
import es from "../../../assets/flag/cif-es.svg";
import vn from "../../../assets/flag/cif-vn.svg";
import it from "../../../assets/flag/cif-it.svg";
import ua from "../../../assets/flag/cif-ua.svg";
import np from "../../../assets/flag/cif-np.svg";
import kz from "../../../assets/flag/cif-kz.svg";
import bd from "../../../assets/flag/cif-bd.svg";
import ro from "../../../assets/flag/cif-ro.svg";
import de from "../../../assets/flag/cif-de.svg";
import ar from "../../../assets/flag/cif-ar.svg";
import th from "../../../assets/flag/cif-th.svg";
import mn from "../../../assets/flag/cif-mn.svg";
import ir from "../../../assets/flag/cif-ir.svg";
import ie from "../../../assets/flag/cif-ie.svg";
import Delete from "@mui/icons-material/Delete";
import Loader from "../Loader/Loader";
const ComicChapter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [chapter, setchapter] = useState({
    chapter: "",
    lang: "",
    title: "",
    file: "",
  });
  const [lang, setLang] = useState({
    lang: "",
    file: "",
  });
  const [comic, setcomic] = useState({});
  const [langImages, setlangImages] = useState({});
  const [imageArr, setimageArr] = useState([]);
  const [imageArrId, setimageArrId] = useState();
  const [loader, setLoader] = useState(false);

  const [editChap, seteditChap] = useState({
    chapter: "",
    title: "",
    id: "",
  });
  const [chapLangAddData, setchapLangAddData] = useState({
    chapter: "",
    id: "",
  });
  const images = {
    en: en,
    fr: fr,
    kr: kr,
    cn: cn,
    tr: tr,
    jp: jp,
    in: In,
    br: br,
    id: id,
    pl: pl,
    ru: ru,
    mx: mx,
    es: es,
    vn: vn,
    it: it,
    ua: ua,
    np: np,
    kz: kz,
    tm: In,
    bd: bd,
    ro:ro,
    de:de,
    ar:ar,
    th:th,
    mn:mn,
    ir:ir,
    ie:ie
  };
  const languages = {
    en: "English",
    fr: "French",
    kr: "Korean",
    cn: "Chinese",
    tr: "Turkey",
    jp: "Japanese",
    in: "Hindi",
    br: "Portuguese",
    id: "Indonesian",
    pl: "Polish",
    ru: "Russian",
    mx: "Mexican",
    es: "Spanish",
    vn: "Vietnamese",
    it: "Italian",
    ua: "Ukranian",
    np: "Nepali",
    kz: "Kazakh",
    tm: "Tamil",
    bd:"Bengali",
    ro:"Romanian",
    de:"German",
    ar:"Arabic",
    th:"Thai",
    mn:"Mongolian",
    ir:"Persian",
    ie:"Irish"
  };
  const options = [
    {
      value: "en",
      label: (
        <div>
          <img
            src={en}
            alt="English"
            style={{ width: "20px", marginRight: "10px" }}
          />
          English
        </div>
      ),
    },
    {
      value: "np",
      label: (
        <div>
          <img
            src={np}
            alt="Nepali"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Nepali
        </div>
      ),
    },
    {
      value: "kz",
      label: (
        <div>
          <img
            src={kz}
            alt="Kazakh"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Kazakh
        </div>
      ),
    },
    {
      value: "ua",
      label: (
        <div>
          <img
            src={ua}
            alt="Ukranian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Ukranian
        </div>
      ),
    },
    {
      value: "it",
      label: (
        <div>
          <img
            src={it}
            alt="Italian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Italian
        </div>
      ),
    },
    {
      value: "fr",
      label: (
        <div>
          <img
            src={fr}
            alt="French"
            style={{ width: "20px", marginRight: "10px" }}
          />
          French
        </div>
      ),
    },
    {
      value: "kr",
      label: (
        <div>
          <img
            src={kr}
            alt="Korean"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Korean
        </div>
      ),
    },
    {
      value: "cn",
      label: (
        <div>
          <img
            src={cn}
            alt="Chinese"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Chinese
        </div>
      ),
    },
    {
      value: "tr",
      label: (
        <div>
          <img
            src={tr}
            alt="Turkey"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Turkey
        </div>
      ),
    },
    {
      value: "jp",
      label: (
        <div>
          <img
            src={jp}
            alt="Japanese"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Japanese
        </div>
      ),
    },
    {
      value: "in",
      label: (
        <div>
          <img
            src={In}
            alt="Hindi"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Hindi
        </div>
      ),
    },
    {
      value: "br",
      label: (
        <div>
          <img
            src={br}
            alt="portuguese"
            style={{ width: "20px", marginRight: "10px" }}
          />
          portuguese
        </div>
      ),
    },
    {
      value: "id",
      label: (
        <div>
          <img
            src={id}
            alt="Indonesian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Indonesian
        </div>
      ),
    },
    {
      value: "pl",
      label: (
        <div>
          <img
            src={pl}
            alt="Polish"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Polish
        </div>
      ),
    },
    {
      value: "ru",
      label: (
        <div>
          <img
            src={ru}
            alt="Russian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Russian
        </div>
      ),
    },
    {
      value: "es",
      label: (
        <div>
          <img
            src={es}
            alt="Spanish"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Spanish
        </div>
      ),
    },
    {
      value: "vn",
      label: (
        <div>
          <img
            src={vn}
            alt="Vietnamese"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Vietnamese
        </div>
      ),
    },
    {
      value: "mx",
      label: (
        <div>
          <img
            src={mx}
            alt="Mexican"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Mexican
        </div>
      ),
    },
    {
      value: "tm",
      label: (
        <div>
          <img
            src={In}
            alt="Tamil"
            style={{ width: "20px", marginRight: "10px" }}
          />
         Tamil
        </div>
      ),
    },
    {
      value: "bd",
      label: (
        <div>
          <img
            src={bd}
            alt="Bengali"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Bengali
        </div>
      ),
    },
    {
      value: "ro",
      label: (
        <div>
          <img
            src={ro}
            alt="Romanian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Romanian
        </div>
      ),
    },
    {
      value: "de",
      label: (
        <div>
          <img
            src={de}
            alt="German"
            style={{ width: "20px", marginRight: "10px" }}
          />
          German
        </div>
      ),
    },
    {
      value: "ar",
      label: (
        <div>
          <img
            src={ar}
            alt="Arabic"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Arabic
        </div>
      ),
    },
    {
      value: "th",
      label: (
        <div>
          <img
            src={th}
            alt="Thai"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Thai
        </div>
      ),
    },
    {
      value: "mn",
      label: (
        <div>
          <img
            src={mn}
            alt="Mongolian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Mongolian
        </div>
      ),
    },
    {
      value: "ir",
      label: (
        <div>
          <img
            src={ir}
            alt="Persian"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Persian
        </div>
      ),
    },
    {
      value: "ie",
      label: (
        <div>
          <img
            src={ie}
            alt="Irish"
            style={{ width: "20px", marginRight: "10px" }}
          />
          Irish
        </div>
      ),
    },
  ];
  const valueOfLanguage = (language) => {
    const option = options.find((option) => option.value == language);
    return option;
  };
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `${Cookies.get("Authorization")}`,
  };
  const headersJson = {
    "Content-Type": "application/json",
    Authorization: `${Cookies.get("Authorization")}`,
  };
  const addChapter = async (e) => {
    try {
      // console.log(chapter.file.name);
      setLoader(true);

      const filename = chapter.file.name;
      const ext = filename.split(".")[1];
      // console.log(ext);
      e.preventDefault();
      if (ext == "cbz" || ext == "cbr") {
        const formData = new FormData();
        formData.append("chapter", chapter.chapter);
        formData.append("lang", chapter.lang);
        formData.append("title", chapter?.title);
        formData.append("file", chapter.file);
        const addChapter = await axios.post(
          `${API_URL}/chapter/comic/${searchParams.get("id")}`,
          formData,
          { headers: headers }
        );
        if (addChapter.data.status == "error") {
          toast.error(addChapter.data.message);
        } else {
          toast.success(addChapter.data.message);
          navigate(0);
          setLoader(false);
        }
      } else {
        toast.warn("file type must be a CBZ");
        setLoader(false);

        return 0;
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message);
    }
  };
  const addChapterLang = async (e) => {
    try {
      setLoader(true);

      e.preventDefault();
      // console.log(chapter.file.name);
      const filename = lang.file.name;
      const ext = filename.split(".")[1];
      if (ext == "cbz" || ext == "cbr") {
        const formData = new FormData();
        formData.append("lang", lang.lang);
        formData.append("file", lang.file);
        const addLang = await axios.post(
          `${API_URL}/chapter/lang/${chapLangAddData.id}`,
          formData,
          { headers: headers }
        );
        if (addLang.data.status == "error") {
          toast.error(addLang.data.message);
          setLoader(false);
        } else {
          toast.success(addLang.data.message);
          navigate(0);
          setLoader(false);
        }
      } else {
        toast.warn("file type must be a CBZ");
        setLoader(false);

        return 0;
      }
    } catch (error) {
      //console.log(error);
      toast.error(error.message);
    }
  };
  const getComic = async () => {
    try {
      const data = await axios.get(
        `${API_URL}/comic/admin/${searchParams.get("id")}`
      );
      setcomic(data.data.data);
    } catch (error) {
      //console.log(error);
      toast.warn(error.message);
    }
  };
  const saveChapterValue = (chapter, id) => {
    setchapLangAddData({ chapter: chapter, id: id });
  };
  const editImage = async (e, file, id) => {
    setLoader(true);

    e.preventDefault();
    // console.log(id, file);
    const formData = new FormData();
    formData.append("file", file);
    const uploadImage = await axios.put(
      `${API_URL}/chapter/lang/image/${id}`,
      formData,
      { headers: headers }
    );
    if (uploadImage.data.status == "error") {
      toast.error(uploadImage.data.message);
      setLoader(false);
    } else {
      toast.success(uploadImage.data.message);
      window.location.reload();
      setLoader(false);
    }
  };
  const ImageArrange = async (data) => {
    //console.log(data);
    setimageArrId(data._id);
    setimageArr(data.images);
  };
  const handleDragEnd = (result) => {
    // console.log(result);
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const copyState = [...imageArr];
    const [reorderData] = copyState.splice(startIndex, 1);
    // console.log(reorderData);
    copyState.splice(endIndex, 0, reorderData);
    setimageArr(copyState);
  };
  const uploadArrangeImage = async (e) => {
    // console.log(imageArr,imageArrId);
    setLoader(true);

    e.preventDefault();
    const allImageArrange = [];
    imageArr.map((data, index) => {
      allImageArrange.push({
        no: index,
        path: data.path,
      });
    });
    // console.log(allImageArrange);
    // console.log(Cookies.get("Authorization"));
    const replace = await axios.put(
      `${API_URL}/chapter/lang/imagearr/${imageArrId}`,
      { data: imageArr },
      {
        headers: headersJson,
      }
    );
    if (replace.data.status == "error") {
      toast.error(replace.data.status);
      setLoader(false);
    } else {
      toast.success(replace.data.status);
      window.location.reload();
      setLoader(false);
    }
  };

  //delete
  const deleteChapter = async (id) => {
    const ans = window.confirm("Are You Sure....???");
    setLoader(true);

    if (ans) {
      const deleteChap = await axios.delete(`${API_URL}/chapter/${id}`, {
        headers: headersJson,
      });
      if (deleteChap.data.status === "error") {
        toast.error(deleteChap.data.message);
        setLoader(false);
      } else {
        toast.success(deleteChap.data.message);
        window.location.reload();
        setLoader(false);
      }
    } else {
      toast.success("no changes in chapter");
      setLoader(false);
    }
  };
  const deleteChapterLang = async (id) => {
    const ans = window.confirm("Are You Sure....???");
    setLoader(true);

    if (ans) {
      const deleteChapLang = await axios.delete(
        `${API_URL}/chapter/lang/${id}`,
        {
          headers: headersJson,
        }
      );
      if (deleteChapLang.data.status === "error") {
        toast.error(deleteChapLang.data.message);
        setLoader(false);
      } else {
        toast.success(deleteChapLang.data.message);
        window.location.reload();
        setLoader(false);
      }
    } else {
      toast.success("no changes in chapter");
      setLoader(false);
    }
  };

  const editChapter = async (data, type) => {
    // console.log(data);
    setLoader(true);

    if (type == "setData") {
      seteditChap({ chapter: data.chapter, title: data?.title, id: data._id });
      setLoader(false);
    } else if (type == "submit") {
      const data = await axios.put(
        `${API_URL}/chapter/${editChap.id}`,
        {
          chapter: editChap.chapter,
          title: editChap?.title,
        },
        { headers: headersJson }
      );
      if (data.data.status == "error") {
        toast.error(data.data.message);
        setLoader(false);
      } else {
        toast.success(data.data.message);
        window.location.reload();
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    const Authorization = Cookies.get("Authorization");
    const token = Authorization?.split(" ")[1];
    // console.log(token);
    if (!token) {
      navigate("/admin_login");
    } else {
      getComic();
    }
  }, []);
  return (
    <div>
      {loader ? <Loader /> : null}

      <div style={{ textAlign: "right", margin: "1rem auto", width: "95%" }}>
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addchapter"
          style={{
            marginRight: "6px",
            position: "fixed",
            right: "10px",
            top: "61px",
            zIndex: "99",
          }}
        >
          <AddBox />
          Add Chapter
        </button>
      </div>
      <div>
        <div
          style={{
            width: "90%",
            margin: "2rem auto",
            backgroundImage: `url(http://localhost:8080/${comic.coverImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backdropFilter: "blur(5px)",
            color: "white",
            backgroudColor: "rgba(0, 0, 0, 0.7)",
            backgroundBlendMode: "multiply",
            // '-webkit-filter': 'blur(5px)',
            //  ' -moz-filter': 'blur(5px)',
            // '-o-filter': 'blur(5px)',
            // '-ms-filter': 'blur(5px)',
          }}
        >
          <div
            Style="
          display: flex;
          justify-content: space-between;
          background-color: rgba(0, 0, 0, 0.7);
          align-items:center;
          font-family:Roboto Condensed;
          letter-spacing:1px;
        "
          >
            <img
              src={`${IMG_URL}${comic.coverImage}`}
              Style="width:10rem;height: 17rem;margin:1rem;filter: drop-shadow(2px 4px 6px black);"
            />
            <div
              style={{ textAlign: "left", width: "65%", margin: "10px auto" }}
            >
              <p>
                Title :- <span>{comic?.title}</span>
              </p>
              <p>
                Description :- <span>{comic.description}</span>
              </p>
              <p>
                Genre :- <span>{comic.genre}</span>
              </p>
              <p>
                Author :- <span>{comic.author}</span>
              </p>
              <p>
                Popular :- <span>{comic.isPopular ? "Yes" : "No"}</span>
              </p>
              <p>
                Top10 :- <span>{comic.isTop10 ? "Yes" : "No"}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          {/*<h1>Chapters:-</h1>*/}
          {Array.isArray(comic.chapter) && comic.chapter.length > 0 ? (
            <div>
              {comic.chapter.map((data, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "95%",
                        margin: "25px auto",
                      }}
                    >
                      <div>
                        <h5
                          Style="font-family:Roboto Condensed;word-spacing: 2px;
                        letter-spacing: 1px;font-weight:bolder"
                        >
                          Chapter :- {data.chapterId?.chapter}
                        </h5>
                        <h6
                          className="text-light-emphasis "
                          Style="font-family:Roboto Condensed;word-spacing: 2px;
                        letter-spacing: 1px;"
                        >
                          Title :- {data.chapterId?.title}
                        </h6>
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#addlang"
                          onClick={() =>
                            saveChapterValue(
                              data.chapterId.chapter,
                              data.chapterId._id
                            )
                          }
                          style={{ marginRight: "6px", height: "3rem" }}
                        >
                          <AddBoxOutlined />
                        </button>
                        <button
                          className="btn btn-outline-warning  "
                          data-bs-toggle="modal"
                          data-bs-target="#editchapter"
                          onClick={() => editChapter(data.chapterId, "setData")}
                          style={{ marginRight: "6px", height: "3rem" }}
                        >
                          <ModeEditOutline />
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => deleteChapter(data.chapterId._id)}
                          style={{ marginRight: "6px", height: "3rem" }}
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                    <table className="table" style={{ textAlign: "center" }}>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Language</th>
                          <th scope="col">Total Images</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.chapterId.lang.map((langData, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                <img src={images[langData.name]} height={25} />
                                &nbsp;
                                <span Style="font-family:Roboto Condensed">
                                  {languages[langData.name]}
                                </span>
                              </td>
                              <td Style="font-family:Roboto Condensed">
                                {langData.images.length}
                              </td>
                              <td>
                                <button
                                  className="btn "
                                  data-bs-toggle="modal"
                                  data-bs-target="#images"
                                  onClick={() => setlangImages(langData)}
                                  style={{ marginRight: "6px" }}
                                >
                                  <EditNoteRounded />
                                </button>
                                <button
                                  className="btn "
                                  data-bs-toggle="modal"
                                  data-bs-target="#imagesarrang"
                                  onClick={() => ImageArrange(langData)}
                                  style={{ marginRight: "6px" }}
                                >
                                  <ImageAspectRatioRounded />
                                </button>
                                <button
                                  className="btn "
                                  onClick={() =>
                                    deleteChapterLang(langData._id)
                                  }
                                  style={{ marginRight: "6px" }}
                                >
                                  <Delete />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontFamily: "Roboto",
                color: "red",
              }}
            >
              {" "}
              Chapter Not found
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="addchapter"
        tabIndex="-1"
        aria-labelledby="addchapter"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Chapter
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              // onSubmit={(e) => {
              //   handle_edit_comics(e);
              // }}
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <TextField
                  id="outlined-basic"
                  label="Chapter"
                  variant="outlined"
                  fullWidth
                  name="chapter"
                  onChange={(e) =>
                    setchapter({ ...chapter, chapter: e.target.value })
                  }
                  className="mb-3"
                  value={chapter.chapter}
                />
                <TextField
                  id="outlined-basic"
                  label="Chapter Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  onChange={(e) =>
                    setchapter({ ...chapter, title: e.target.value })
                  }
                  className="mb-3"
                  value={chapter?.title}
                />
                <Select
                  options={options}
                  isSearchable
                  onChange={(e) => setchapter({ ...chapter, lang: e.value })}
                ></Select>
                <input
                  type="file"
                  name="cover"
                  className="form-control"
                  style={{ marginTop: "2rem" }}
                  onChange={(e) =>
                    setchapter({ ...chapter, file: e.target.files[0] })
                  }
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={(e) => addChapter(e)}
                  className="btn btn-primary"
                >
                  Add Chapter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editchapter"
        tabIndex="-1"
        aria-labelledby="editchapter"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Chapter
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              // onSubmit={(e) => {
              //   handle_edit_comics(e);
              // }}
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <TextField
                  id="outlined-basic"
                  label="Chapter"
                  variant="outlined"
                  fullWidth
                  name="chapter"
                  onChange={(e) =>
                    seteditChap({ ...editChap, chapter: e.target.value })
                  }
                  className="mb-3"
                  value={editChap.chapter}
                />
                <TextField
                  id="outlined-basic"
                  label="Chapter Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  onChange={(e) =>
                    seteditChap({ ...editChap, title: e.target.value })
                  }
                  className="mb-3"
                  value={editChap?.title}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    editChapter("", "submit");
                  }}
                  className="btn btn-primary"
                >
                  Add Chapter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addlang"
        tabIndex="-1"
        aria-labelledby="addlang"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Language
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              // onSubmit={(e) => {
              //   handle_edit_comics(e);
              // }}
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <TextField
                  id="outlined-basic"
                  label="Comic Name"
                  variant="outlined"
                  fullWidth
                  name="comicname"
                  disabled
                  className="mb-3"
                  value={comic?.title}
                />
                <TextField
                  id="outlined-basic"
                  label="Chapter"
                  variant="outlined"
                  fullWidth
                  disabled
                  name="chapter"
                  className="mb-3"
                  value={chapLangAddData.chapter}
                />
                <Select
                  options={options}
                  isSearchable
                  onChange={(e) => setLang({ ...lang, lang: e.value })}
                ></Select>
                <input
                  type="file"
                  name="cover"
                  className="form-control"
                  style={{ marginTop: "2rem" }}
                  onChange={(e) =>
                    setLang({ ...lang, file: e.target.files[0] })
                  }
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={(e) => addChapterLang(e)}
                  className="btn btn-primary"
                >
                  Add Language
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="images"
        tabIndex="-1"
        aria-labelledby="images"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ maxWidth: "82%" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Chapter Images
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                margin: "0px 2rem",
                marginLeft: "2.5rem",
                marginBottom: "1rem",
                columnGap: "1rem",
              }}
            >
              {langImages.images &&
                langImages.images.map((data, index) => {
                  //console.log(data._id);
                  return (
                    <div className="flex" key={index}>
                      <label
                        htmlFor={index}
                        className="w-[30%] ml-[6.8vw] mt-5 border border-slate-400 block"
                      >
                        <img
                          src={`${IMG_URL}${data.path}`}
                          style={{ width: "100px", height: "100px" }}
                          alt=""
                          className="rounded"
                        />
                      </label>
                      <input
                        type="file"
                        onChange={(e) =>
                          editImage(e, e.target.files[0], data._id)
                        }
                        id={index}
                        style={{ display: "none" }}
                        placeholder="enter the photo"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="imagesarrang"
        tabIndex="-1"
        aria-labelledby="imagesarrang"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ maxWidth: "62%", textAlign: "center" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Images Arranging
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="imageArr">
                {(DroppableProvider) => (
                  <div
                    ref={DroppableProvider.innerRef}
                    {...DroppableProvider.droppableProps}
                  >
                    {imageArr.map((data, index) => {
                      return (
                        <Draggable
                          index={index}
                          key={data.no}
                          draggableId={data.no.toString()}
                        >
                          {(DraggableProvider) => (
                            <div
                              ref={DraggableProvider.innerRef}
                              {...DraggableProvider.draggableProps}
                              {...DraggableProvider.dragHandleProps}
                            >
                              <div
                                Style="display: flex;
                              justify-content: space-evenly;
                              align-items: center;
                              width: 100%;
                              margin: 0px auto;"
                              >
                                <img
                                  src={`${IMG_URL}${data.path}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    margin: "20px 20px",
                                    border: "1px solid rgba(0,0,0,0.2)",
                                  }}
                                  className=" rounded"
                                />
                                <p Style="width:40%;word-wrap:break-word">
                                  {data.path.split("/")[4]}
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {DroppableProvider.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={(e) => uploadArrangeImage(e)}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComicChapter;
