import React, { useEffect, useState } from "react";
import Cookies from'js-cookie';
import axios from "axios";
import { API_URL } from "../../../Config";
import { toast } from "react-toastify";
const Copyright_Terms = () => {
  const [data, setdata] = useState({
    copyright: "",
    terms: "",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: Cookies.get("Authorization"),
  };
  const getData = async()=>{
    try {
        const data = await axios.get(`${API_URL}/terms/`);
        if( data.data.status === "error"){
            return 0;
        }else{
            setdata({copyright:data.data.data.copyright,terms:data.data.data.termsCondition})
        }
    } catch (error) {
        //console.log(error);
    }
  }
  const addData = async()=>{
    try {
        const addData = await axios.post(`${API_URL}/terms/`,data,{headers:headers});
        if( addData.data.status === "error"){
            toast.error(addData.data.message)
        }else{
            toast.success(addData.data.message)
        }
    } catch (error) {
        //console.log(error);
    }
  }
  const deleteData = async()=>{
    try {
        const deleteData = await axios.delete(`${API_URL}/terms/`,{headers});
        if( deleteData.data.status === "error"){
            toast.error(deleteData.data.message)
        }else{
            toast.success(deleteData.data.message)
            setdata({copyright:'',terms:''})
        }
    } catch (error) {
        //console.log(error);
    }
  }
  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div Style="width:90%;margin:0px auto;">
      <div>
        <div className="form-floating my-5">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            Style="height: 200px"
            value={data.copyright}
            onChange={(e) => {
              setdata({ ...data, copyright: e.target.value });
            }}
          ></textarea>
          <label for="floatingTextarea2">Terms & Condition Section</label>
        </div>
        <div className="form-floating my-5">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            Style="height: 200px"
            value={data.terms}
            onChange={(e) => {
              setdata({ ...data, terms: e.target.value });
            }}
          ></textarea>
          <label for="floatingTextarea2">CopyRight Section</label>
        </div>
      </div>
      <div className=" d-flex justify-content-center">
        <button className="btn btn-outline-success mx-2" onClick={()=>addData()}>SUBMIT</button>
        <button className="btn btn-danger mx-2" onClick={()=>deleteData()}>DELETE</button>
      </div>
    </div>
  );
};

export default Copyright_Terms;
